import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { StoryblokComponent, StoryblokStory, useStoryblokState } from "gatsby-source-storyblok"

const OffersPage = ({ data }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)
  let blok = story.content
  return (
    <Layout>
      <SEO title="Offers" />
      <StoryblokComponent blok={blok} key={blok._uid} />
    </Layout>
  )
}

export default OffersPage

export const query = graphql`
  query offersQuery {
    storyblokEntry(full_slug: { eq: "offers" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`
